import style from './ExperienceStep.module.css'
import React from 'react';

import egeraLogo from '../../assets/logos/egeraLogo.png'
import highScoreGamesLogo from '../../assets/logos/highScoreGamesLogo.png'
import pixelsInteractiveLogo from '../../assets/logos/pixelsInteractiveLogo.png'
import malagaByteLogo from '../../assets/logos/malagaByteLogo.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserGraduate, faGraduationCap, faTerminal, faPenNib, faDesktop, faFileCode, faPeniclRuler, faPencilRuler } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment'
import 'moment/locale/pl'

import Translate from '../Translate/Translate'

function ExperienceStep({title, position, years, icon, subtitle, durationFrom, durationTo, groupBy, marginTopZero}){
    return(
        <div className={style.expStep}>
            <div className={style.icon} style={icon == 'highScoreGames' || icon == 'pixelsInteractive' || icon == 'egera' ? {background: 'transparent'} : null}>
                {
                    icon === 'edu1' ? <FontAwesomeIcon icon={faUserGraduate} /> :
                    icon === 'edu2' ? <FontAwesomeIcon icon={faGraduationCap} /> :
                    icon === 'work1' ? <FontAwesomeIcon icon={faTerminal} /> :
                    icon === 'work2' ? <FontAwesomeIcon icon={faDesktop} /> :
                    icon === 'work3' ? <FontAwesomeIcon icon={faPenNib} /> :
                    icon === 'work4' ? <FontAwesomeIcon icon={faFileCode} /> : 
                    icon === 'egera' ? <img src={egeraLogo} /> : 
                    icon === 'highScoreGames' ? <img src={highScoreGamesLogo} /> : 
                    icon === 'pixelsInteractive' ? <img src={pixelsInteractiveLogo} /> : 
                    icon === 'malagaByte' ? <img src={malagaByteLogo} /> : 
                    icon === 'work5' ? <FontAwesomeIcon icon={faPencilRuler} /> : null
                }
            </div>
            <div className={style.content}>
                <span className={style.title}>{title} {subtitle ? <span className={style.subtitle}>({subtitle})</span> : null}</span>
                <span className={style.years}>
                    {years} 
                    {
                        durationTo != null ? 
                        <span className={style.durationLabel}>({moment(durationTo).diff(moment(durationFrom), groupBy)} {groupBy == 'years' ? <Translate>years</Translate> : (moment(durationTo).diff(moment(durationFrom), groupBy) == 1 ? <Translate>month</Translate> : <Translate>months</Translate>)})</span>
                        : <span className={style.durationLabel}>({moment(durationFrom).toNow(true)})</span>
                    }
                    
                </span>
                <span style={marginTopZero ? {marginTop: '0px'} : null} className={style.position}>{position}</span>
            </div>
        </div>
    )
}

export default ExperienceStep