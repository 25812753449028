import Translate from '../Translate/Translate';
import Language from './Language/Language';
import style from './Languages.module.css'
import React from 'react';

function Languages(){
    return(
        <div className={style.languagesBox}>
            <Language lang={<Translate>Polish</Translate>} level={<Translate>Native</Translate>} country="pl" />
            <Language lang={<Translate>English</Translate>} level={<Translate>Very good (C1)</Translate>} country="en" />
            <Language lang={<Translate>German</Translate>} level={<Translate>Basics</Translate>} country="de" />
            <Language lang={<Translate>Spanish</Translate>} level={<Translate>Basics (A1)</Translate>} country="es" />
        </div>
    )
}

export default Languages;