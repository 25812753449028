import style from './Service.module.css'
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode, faPalette, faCameraRetro, faFilm, faHelicopter } from '@fortawesome/free-solid-svg-icons'

function Service({title, icon, desc}){

    return(
        <div className={style.serviceBox}>
            <div className={style.icon}>
                {
                    icon === 'code' ? <FontAwesomeIcon icon={faCode} /> :
                    icon === 'design' ? <FontAwesomeIcon icon={faPalette} /> :
                    icon === 'video' ? <FontAwesomeIcon icon={faFilm} /> :
                    icon === 'drone' ? <FontAwesomeIcon icon={faHelicopter} /> : 
                    icon === 'photo' ? <FontAwesomeIcon icon={faCameraRetro} /> : null
                }
            </div>
            <div style={{display: 'flex', flexDirection: 'column', justifyItems: 'flex-start', gap: '3px'}}>
                <div className={style.title}>{title}</div>
                <div className={style.desc}>{desc}</div>
            </div>
        </div>
    )
}

export default Service;