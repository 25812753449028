import Title from '../Title/Title'
import style from './HistoryWork.module.css'
import ExperienceStep from '../ExperienceStep/ExperienceStep'
import Translate from '../Translate/Translate'
import React from 'react';

function HistoryWork({history}){
    return(
        <div className={style.workBox}>
            <Title text={<Translate>Professional experience</Translate>} />
            {
                history.map(his => {
                    return(
                        <ExperienceStep groupBy={his.groupBy} durationFrom={his.fromTimestamp} durationTo={his.toTimestamp} title={his.title} position={his.position} years={his.years} subtitle={his.subtitle} icon={his.icon} />
                    )
                })
            }
        </div>
    )
}

export default HistoryWork