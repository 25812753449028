import style from './App.module.css';
import { Route, Switch, withRouter } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Sidebar from './components/Sidebar/Sidebar';
import Contact from './pages/Contact/Contact';
import Design from './pages/Design/Design';
import Blog from './pages/Blog/Blog';
import Experience from './pages/Experience/Experience'
import Code from './pages/Code/Code' 
import Photo from './pages/Photo/Photo'
import Home from './pages/Home/Home';
import Footer from './components/Footer/Footer';
import Favicon from 'react-favicon';
import React, { useEffect } from 'react';
import Mm from './pages/Mm/Mm'
import ReactGA from "react-ga4";

import Translate from './components/Translate/Translate';
import favicon from './assets/favicon.png';
import { useState } from 'react';
import SingleCode from './pages/SingleCode/SingleCode';

export const LangContext = React.createContext(null);

function App() {
  let windowLocation = window.location.pathname;
  windowLocation.substring(1,windowLocation.length) == '' ? document.title = 'Jan Mager' :
  windowLocation.substring(1,windowLocation.length) == 'experience' ? document.title = `Jan Mager | Doświadczenie` : 
  windowLocation.substring(1,windowLocation.length) == 'design' ? document.title = 'Jan Mager | Grafika' : 
  windowLocation.substring(1,windowLocation.length) == 'code' ? document.title = 'Jan Mager | Programowanie' : 
  windowLocation.substring(1,windowLocation.length) == 'photo' ? document.title = 'Jan Mager | Fotografia' : 
  windowLocation.substring(1,windowLocation.length) == 'blog' ? document.title = 'Jan Mager | Blog' : 
  windowLocation.substring(1,windowLocation.length) == 'contact' ? document.title = 'Jan Mager | Kontakt' : document.title = 'Jan Mager'

  const [ lang, setLang ] = useState(navigator.language || navigator.userLanguage)

  ReactGA.initialize("G-0Y5N7GX0BF");
  
  useEffect(() => {
    if(lang.indexOf('pl') == -1) setLang('en')
    else setLang('pl')
  }, [lang])

  return (
    <LangContext.Provider value={lang}>
      <div className={style.App}>
        <Favicon url={favicon} />
        <Navbar setLang={setLang} />
        <div className={style.mainContainer}>
          {(windowLocation.substring(1,windowLocation.length) == 'about' || windowLocation.substring(1,windowLocation.length) == 'contact' || windowLocation.substring(1,windowLocation.length) == 'experience') ? <Sidebar /> : ''}
          <div className={style.rightWrapper}>
            <div className={style.mainContent}>
              <Switch>
                <Route path="/" exact>
                  <Code />
                </Route>
                <Route path="/about">
                  <Home />
                </Route>
                <Route path="/experience">
                  <Experience />
                </Route>
                <Route path="/design">
                  <Design />
                </Route>
                <Route path="/code/:slug">
                  <SingleCode />
                </Route>
                {/* <Route path="/code">
                  <Code />
                </Route> */}
                <Route path="/photo">
                  <Photo />
                </Route>
                <Route path="/blog">
                  <Blog />
                </Route>
                <Route path="/mm">
                  <Mm />
                </Route>
                <Route path="/contact">
                  <Contact />
                </Route>
              </Switch>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </LangContext.Provider>
  );
}

export default withRouter(App);
