import Translate from '../components/Translate/Translate'

import thumb1 from '../assets/codePortfolio/01.png'
import ui1 from '../assets/codePortfolio/01_full_ui.png'
import thumb2 from '../assets/codePortfolio/02.png'
import thumb3 from '../assets/codePortfolio/03.png'
import thumb4 from '../assets/codePortfolio/04.png'
import thumb5 from '../assets/codePortfolio/05.png'
import thumb6 from '../assets/codePortfolio/06.png'
import thumb7 from '../assets/codePortfolio/07.png'
import thumb8 from '../assets/codePortfolio/08.png'
import thumb9 from '../assets/codePortfolio/09.png'
import thumb10 from '../assets/codePortfolio/10.png'
import ui10 from '../assets/codePortfolio/10_full_ui.png'
import thumb11 from '../assets/codePortfolio/11.png'
import ui11 from '../assets/codePortfolio/11_full_ui.png'
import thumb12 from '../assets/codePortfolio/12.png'
import ui12 from '../assets/codePortfolio/12_full_ui.png'
import thumb13 from '../assets/codePortfolio/13.png'
import ui13 from '../assets/codePortfolio/13_full_ui.png'
import thumb14 from '../assets/codePortfolio/14.png'
import ui14 from '../assets/codePortfolio/14_full_ui.png'
import thumb15 from '../assets/codePortfolio/15.png'
import ui15 from '../assets/codePortfolio/15_full_ui.png'
import thumb16 from '../assets/codePortfolio/16.png'
import ui16 from '../assets/codePortfolio/16_full_ui.png'
import thumb17 from '../assets/codePortfolio/17.png'
import ui17 from '../assets/codePortfolio/17_full_ui.png'
import thumb18 from '../assets/codePortfolio/18.png'
import ui18 from '../assets/codePortfolio/18_full_ui.jpg'
import thumb20 from '../assets/codePortfolio/20.png'
import ui20 from '../assets/codePortfolio/20_full_ui.jpg'
import thumb21 from '../assets/codePortfolio/21.png'
import ui21 from '../assets/codePortfolio/21_full_ui.jpg'
import thumb22 from '../assets/codePortfolio/22.png'
import ui22 from '../assets/codePortfolio/22_full_ui.jpg'
import thumb23 from '../assets/codePortfolio/23.png'
import ui23 from '../assets/codePortfolio/23_full_ui.jpg'
import thumb24 from '../assets/codePortfolio/24.png'
import ui24 from '../assets/codePortfolio/24_full_ui.jpg'
import thumb25 from '../assets/codePortfolio/25.png'
import ui25 from '../assets/codePortfolio/25_full_ui.png'
import thumb26 from '../assets/codePortfolio/26.png'
import ui26 from '../assets/codePortfolio/26_full_ui.jpg'

const codePortfolio = [
    // {
    //     bigName: 'Primresim',
    //     smallName: 'e-commerce',
    //     name: 'Primresim - ecommerce',
    //     desc: 'Online store written from scratch using Next.js technology, database operated by Firebase. Payments processed by Stripe. Original design, based on Tailwind.css',
    //     p2: "Next.js is a modern React framework that ensures lightning-fast page loading. Firebase is Google's cloud platform that offers a secure and scalable database and authorization. Stripe - a platform that allows you to easily integrate payments. Tailwind.css allows you to create a responsive and aesthetic design without writing unnecessary code.",
    //     langs: ['Next.js', 'Firebase', 'Stripe', 'Tailwind', 'Vercel'],
    //     url: 'https://primresim.com',
    //     thumb: thumb18,
    //     uiPreview: ui18,
    //     slug: 'primresim'
    // },
    // {
    //     bigName: 'FinanceBay',
    //     smallName: 'finances assistant',
    //     name: 'FinanceBay - assistant for your finances',
    //     desc: 'A modern application to manage your budget, control all expenses, income, recurring payments, check reports from individual categories. This application will help you manage and save money.',
    //     langs: ['React.js', 'Next.js', 'TailwindCSS', 'Firebase'],
    //     url: 'https://finance-bay.vercel.app',
    //     thumb: thumb15,
    //     uiPreview: ui15,
    //     disableLive: false,
    //     slug: 'financebay'
    // },
    // {
    //     bigName: 'CapturedByJan',
    //     smallName: 'photography portfolio',
    //     name: 'CapturedByJan.art - photography portfolio',
    //     desc: "A private photo portfolio containing a collection of photos and videos (mainly from a drone). Author's blog system using Headless CMS Sanity.io (entering new photos, posts, products to the store), e-commerce system with payment connection using Stripe.",
    //     langs: ['React.js', 'LeafletMap', 'Next.js', 'Sanity.io', 'Stripe'],
    //     url: 'https://capturedbyjan.art',
    //     thumb: thumb14,
    //     uiPreview: ui14,
    //     disableLive: false,
    //     slug: 'capturedbyjan'
    // },
    // {
    //     bigName: 'NeonKomunikator',
    //     smallName: 'text messenger',
    //     name: "NeonKomunikator - text messenger",
    //     desc: "Text messenger project made with Next.js, TailwindCSS, Prisma, TypeScript. The database of users and conversations is maintained using MongoDB. Registration possible with NextAuth authorization - logging in via Google or Github account.",
    //     langs: ['Next.js', 'TailwindCSS', 'TypeScript', 'MongoDB', 'Prisma'],
    //     url: 'https://neon-komunikator.vercel.app',
    //     thumb: thumb16,
    //     uiPreview: ui16,
    //     disableLive: false,
    //     slug: 'neon-komunikator'
    // },
    // {
    //     bigName: 'CryptoFolio',
    //     smallName: 'cryptocurrency manager',
    //     name: "CryptoFolio.io - a portfolio of cryptocurrency transactions",
    //     desc: "An application created to manage investments in cryptocurrencies. The system works fully on any system and device. CryptoFolio.io allows you to track current cryptocurrency rates, track profits and losses on the spot market and future positions. The user has full access to transaction history , daily automatic reports sent to e-mail and many other functions.",
    //     langs: ['React.js', 'Firebase', 'Socket.io', 'Node.js'],
    //     url: 'https://crypto.janmager.pl',
    //     thumb: thumb13,
    //     uiPreview: ui13,
    //     disableLive: true,
    //     slug: 'cryptofolio'
    // },
    // {
    //     bigName: 'Typerka 3.0',
    //     smallName: 'mobile game',
    //     name: "Typerka - betting on matches",
    //     desc: "Internet application (game), created for the purpose of learning ReactJS, Firebase and Node.js. The site allows you to set up an account and later edit it, the user has the option to bet on the exact result of matches for the currently played league. The application works dynamically and is fully responsive. A backend based on Node.js has been built, it checks match results in real time, updates the ranking and sends players notifications about upcoming matches and summarizing the match day.",
    //     langs: ['React.js', 'Firebase', 'RWD', 'Bootstrap', 'PWA'],
    //     // url: 'https://www.typerka.com',
    //     thumb: thumb12,
    //     uiPreview: ui12,
    //     disableLive: true,
    //     slug: 'typerka-3.0'
    // },
    // {
    //     bigName: 'Typerka 4.0',
    //     smallName: 'mobile game',
    //     name: "Typerka - betting on matches",
    //     desc: "Web application (game), created using the Next.js framework, Supabase database, Node.js server. Kinde Auth was used for authorization. The website allows you to create an account and later edit it, the user can bet on the exact match result for the currently played Euro 2024. The application works dynamically and is fully responsive. A backend based on Node.js was built, it checks match results in real time, updates the ranking and sends players notifications about upcoming matches and summarizing the match day.",
    //     langs: ['Next.js', 'RWD', 'Supabase', 'Node.js', 'Mailer', 'Kinde Auth'],
    //     // url: 'https://www.typerka.com',
    //     thumb: thumb26,
    //     uiPreview: ui26,
    //     disableLive: true,
    //     slug: 'typerka-4.0'
    // },
    // {
    //     bigName: 'Eatspace',
    //     smallName: 'restaurant system',
    //     name: "Eatspace - restaurant system",
    //     desc: "EatSpace is the perfect tool for planning unique culinary experiences. Easily book a table at your favorite restaurant, and additionally benefit from the option of choosing dishes, table or payment before arriving.",
    //     p2: "Browse an extensive database of restaurants in your region that opens the door to endless culinary adventures. Discover new places and rate restaurants. Book a table in a few moments, without having to call or visit the restaurant in person. Select the date, time, specific table and meals you want to order. Application created during the defense of an engineering thesis during IT studies.",
    //     langs: ['React.js', 'RWD', 'Firebase', 'Node', 'React Timeline'],
    //     // url: 'https://www.typerka.com',
    //     thumb: thumb25,
    //     uiPreview: ui25,
    //     disableLive: true,
    //     slug: 'eatspace'
    // },
    // {
    //     bigName: 'Egera',
    //     smallName: 'website',
    //     name: "Egera - cryptocurrency exchange",
    //     desc: "Graphic design and coding of the sales page for the Egera cryptocurrency exchange, the entire portal includes a blog, operations on amounts, animations, a language change system, and forms. Site fully optimized for PageSpeed ​​Lighthouse results.",
    //     langs: ['PageSpeed', 'RWD', 'HTML', 'PHP', 'CSS', 'jQuery', 'Bootstrap', 'API'],
    //     // url: 'https://www.egera.com',
    //     thumb: thumb20,
    //     uiPreview: ui20,
    //     disableLive: true,
    //     slug: 'egera'
    // },
    // {
    //     bigName: 'Panel Egera',
    //     smallName: 'user panel',
    //     name: "Panel Egera - cryptocurrency exchange",
    //     desc: "Graphic design and layout of the user panel of the Egera cryptocurrency exchange. The customer can quickly deposit and withdraw funds and purchase cryptocurrencies via interactive widgets. The website is fully optimized for full accessibility from all devices.",
    //     langs: ['PageSpeed', 'RWD', 'HTML', 'PHP', 'CSS', 'jQuery', 'Bootstrap', 'Chart.js', 'API'],
    //     // url: 'https://www.egera.com',
    //     thumb: thumb21,
    //     uiPreview: ui21,
    //     disableLive: true,
    //     slug: 'panel-egera'
    // },
    {
        bigName: 'Bitomat Egera',
        smallName: 'website',
        name: "Bitomat Egera - buy, sell, withdraw cryptocurrencies",
        desc: "Advertising website for the physical Egera Bitcoin Bitomat, the device allows the stationary purchase of various cryptocurrencies for cash, their sale and cash withdrawal. The website is maximally optimized for Google positioning and Google Pagespead Lighthouse.",
        langs: ['PageSpeed', 'Supabase', 'RWD', 'HTML', 'PHP', 'CSS', 'jQuery', 'Tailwind', 'API'],
        // url: 'https://www.egera.com',
        thumb: thumb22,
        uiPreview: ui22,
        disableLive: true,
        slug: 'bitomat-egera'
    },
    // {
    //     bigName: 'Bitomat Egera UI',
    //     smallName: 'bitcoin atm ui',
    //     name: "Bitomat Egera - buy, sell, withdraw cryptocurrencies",
    //     desc: "Graphic design and coding of the entire flow for the physical interface of the Egera bitcoin bitcoin machine. Interface based on the Chrome engine, adapted pixel perfect for a FullHD ATM display. Dynamic component loading, three language versions, API connection refreshing in the background. Communication with an external printer of sales and purchase confirmations.",
    //     langs: ['PageSpeed', 'Supabase', 'RWD', 'HTML', 'PHP', 'CSS', 'jQuery', 'Tailwind', 'API'],
    //     // url: 'https://www.egera.com',
    //     thumb: thumb24,
    //     uiPreview: ui24,
    //     disableLive: true,
    //     slug: 'bitomat-egera-ui'
    // },
    // {
    //     bigName: 'TradeBest',
    //     smallName: 'real estate service',
    //     name: 'TradeBest - Convenient Real Estate Service',
    //     desc: 'A modern, original portal for searching and adding advertisements for the sale and rental of houses, apartments and plots. Portal written using Next.js, based on the Firebase database.',
    //     langs: ['React.js', 'Next.js', 'TailwindCSS', 'Firebase'],
    //     url: 'https://tradebest.vercel.app',
    //     thumb: thumb17,
    //     uiPreview: ui17,
    //     disableLive: true,
    //     slug: 'tradebest'
    // },
    // {
    //     bigName: 'Where is Shelter',
    //     smallName: 'interactive map of shelters',
    //     name: "Interactive map with the location of shelters",
    //     desc: "Internet application presenting a list of the nearest shelters in Poznań in a clear way for the user in the form of an interactive map. The website was made using the ReactJS framework, works fully on any device and without reloading the page. The user can quickly and conveniently find a shelter and get more information about it (navigation link, shelter capacity).",
    //     langs: ['React.js', 'Bootstrap', 'RWD', 'Leaflet'],
    //     // url: 'https://www.znajdzschron.pl',
    //     thumb: thumb11,
    //     uiPreview: ui11,
    //     disableLive: true,
    //     slug: 'znajdz-schron'
    // },
    // {
    //     bigName: 'Test of T.Kilmann\'s',
    //     smallName: 'interactive test',
    //     name: "Check your negotiating style",
    //     desc: "Thomas Kilmann's interactive test, thanks to which you can learn your negotiation style. You will be presented with 30 situations, you have to choose the closest to your actual reaction. Technologies such as PWA (Progressive Web App), Chart.js were used in this project (graphs at the end of the test) and full responsiveness and operation without reloading the page.",
    //     langs: ['html', 'css', 'rwd', 'jQuery', 'Chart.js', 'PWA'],
    //     url: 'https://www.styl-prowadzenia-negocjacji.janmager.pl',
    //     thumb: thumb1,
    //     uiPreview: ui1,
    //     disableLive: false,
    //     slug: 'styl-prowadzenia-negocjacji'
    // },
    // {
    //     name: 'Tłumacz internetowy',
    //     desc: 'Prosta aplikacja stworzona w czasie nauki frameworka Vue.js, automatycznie wykrywa język wpisanego słowa i tłumaczy je na wcześniej wybrany przez użytkownika język. Aplikacja działa w trybie onepage, dane są pobierane z serwisu Yandex, jest w pełni responsywna dzięki Bootstrap.',
    //     langs: ['Vue.js', 'css', 'Api', 'jQuery', 'Bootstrap', 'RWD'],
    //     url: 'https://www.mager.tech/translate',
    //     thumb: thumb2
    // },
    {
        bigName: 'Translator',
        smallName: 'translator',
        name: "Translator - translate json objects",
        desc: "A simple translator created for work at Egera. The user can provide the object in the format specified in the instructions, as a result of which he will receive all words and sentences automatically translated into the defined (18) languages ​​in the code. The application was created using the React framework, translation is provided by the Google Translate API service.",
        langs: ['React.js', 'RWD', 'DaisyUI', 'Google API'],
        // url: 'https://www.typerka.com',
        thumb: thumb23,
        uiPreview: ui23,
        disableLive: true,
        slug: 'translator'
    },
    {
        bigName: 'RecipeMe',
        smallName: 'culinary recipes',
        name: 'RecipeMe - interactive cookbook',
        desc: 'A web application using the API of the recipe database from the Edamam.com website, where we can dynamically search for recipes without reloading the page. The project allows you to select results through filters. The website is adapted to every device.',
        langs: ['React.js', 'css', 'FontAwesome', 'API', 'JSX', 'RWD'],
        url: 'https://www.recipeme.janmager.pl',
        thumb: thumb3,
        slug: 'recipe-me'
    },
    {
        bigName: 'Portfolio',
        smallName: 'my previous portfolio',
        name: "My previous portfolio",
        desc: "A static website presenting my person and programming portfolio as well as graphically. The website is fully responsive, very well optimized, it also contains a photo gallery and a contact form.",
        langs: ['html', 'css', 'jquery', 'php', 'rwd'],
        thumb: thumb10,
        uiPreview: ui10,
        disableLive: true,
        slug: 'last-portfolio'
    },
    // {
    //     bigName: 'SPACER',
    //     smallName: 'NASA photo database',
    //     name: 'SPACER - NASA photo database',
    //     desc: 'A web application using the NASA photo database API, we can dynamically search for a given gallery by entering any word related to space, NASA, galaxy, etc. Without reloading the page, thanks to Vue.JS, we immediately get a photo gallery in the CSS grid with descriptions straight from the National Aeronautics Agency and Outer Space.',
    //     langs: ['Vue.js', 'css', 'CSS Grid', 'API', 'jQuery', 'RWD'],
    //     url: 'https://www.spacer.janmager.pl',
    //     thumb: thumb4,
    //     slug: 'spacer'
    // },
    // {
    //     name: 'Wizytówka internetowa Adam Tarunt',
    //     desc: 'Projekt wizytówki internetowej dla Adama Tarunt, strona oparta na Wordpressie, w pełni responsywna. Witryna posiada system CMS (blog) - możliwość publikowania w łatwy sposób postów poprzez panel administracyjny.',
    //     langs: ['wordpress', 'rwd', 'jQuery', 'pwa'],
    //     url: 'http://www.tarunt.pl',
    //     thumb: thumb9
    // },
    // {
    //     name: 'Praktyk Biznesu Siecioweg',
    //     desc: 'Strona internetowa/Blog dla Konrada Gandery, oparta na Wordpressie. Od początku wykonana przeze mnie (postawienie na serwerze i szata graficzna), do chwili obecnej administrowana (usuwanie błędów, dodawanie nowych funkcjonalności itp).',
    //     langs: ['wordpress', 'php', 'css', 'sql', 'rwd'],
    //     url: 'https://www.praktykbiznesusieciowego.pl',
    //     thumb: thumb8
    // },
    // {
    //     name: 'Quiz - kwalifikacja E.12',
    //     desc: 'Aplikacja internetowa umożliwiająca sprawdzenie swojej wiedzy w zakresie zawodu technik-informatyk - kwalifikacja E.12. Na stronie znajdziesz 3 tryby - losowe pytanie, próbny egzamin E.12 oraz tryb rywalizacji. Powodzenia!',
    //     langs: ['html', 'css', 'javascript', 'jquery', 'php', 'MySQL'],
    //     url: 'https://www.mager.tech/e12',
    //     thumb: thumb6
    // },
    // {
    //     name: 'Portfolio onepage szablon',
    //     desc: 'Szablon do wykorzystania jako internetowe portfolio. Strona jest wykonana w technologii onepage (bez podstron) przy pełnej optymalizacji na różnych urządzeniach (telefon, tablet itp.). Witryna jest podzielona kilka sekcji, takich jak: o mnie, lista projektów i umiejętności, statystyki w liczbach, formularz kontaktowy oraz sekcja gdzie jest możliwość umówienia się na lekcje.',
    //     langs: ['html', 'css', 'jQuery', 'Bootstrap', 'RWD'],
    //     url: 'https://mager.tech/clean-onepage-portfolio',
    //     thumb: thumb5
    // },
    // {
    //     name: 'Portfolio osobiste dla programisty',
    //     desc: 'Portfolio wykonane na zlecenie dla programisty. Strona jest w pełni responsywna oraz podzielona na kilka podstron. Na pierwszej znajduje się kilka kluczowych informacji, takich jak, krótka notka o osobie, statystyki w liczbach, slider z opiniami. Inne podstrony prezentują listę z prowadzonymi lekcjami wraz z odsyłaczem do zapisania sie na jedną z nich oraz formularz kontaktowy. Strona jest dostępna w dwóch języch (możliwość zmiany przyciskami przypiętymi do lewej strony.',
    //     langs: ['html', 'css', 'jQuery', 'Bootstrap', 'RWD'],
    //     url: 'https://mager.tech/portfolio-developer',
    //     thumb: thumb7
    // }
]

export default codePortfolio