import { useEffect, useState, useContext } from 'react';
import style from './Navbar.module.css'
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faBars } from '@fortawesome/free-solid-svg-icons'
import SocialIcon from '../SocialIcon/SocialIcon';
import './Navbar.css'
import React from 'react';
import logo from '../../assets/logo.png'

import Translate from '../Translate/Translate'

import pl from '../../assets/langs/pl.png'
import en from '../../assets/langs/en.png'

import { LangContext } from '../../App'

function Navbar({ setLang }){
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [menuDisplay, setMenuDisplay] = useState('flex');

    const ln = useContext(LangContext);

    let resizeWindow = () => {
        if(window.innerWidth > 950){
            setMenuIsOpen(true);
            setMenuDisplay('flex');
        }
        else{
            setMenuIsOpen(false);
            setMenuDisplay('none');
        }
    };

    function clickedMenu(){
        if(window.innerWidth <= 950){
            setMenuIsOpen(false);
            setMenuDisplay('none');
        }
    }
    
    function showResponsiveMenu(){
        setMenuIsOpen(!menuIsOpen);
    }

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
    }, []);

    useEffect(() => {
        if(menuIsOpen) setMenuDisplay('flex');
        else setMenuDisplay('none');
    }, [menuIsOpen])

    return(
        <div className={style.navbar}>
            {/* <div className={style.navLeft}>
                <NavLink to="/" className={style.homeButton}>
                    <FontAwesomeIcon icon={faHome} />
                </NavLink>
            </div> */}
            <div className={style.navLeft}>
                <NavLink to="/"><img src={logo} height={32} /></NavLink>
            </div>
            <div className={style.navRight}>
                <div className={style.wrapper} style={{display: menuDisplay}}>
                    <NavLink onClick={clickedMenu} to="/about"><Translate>About me</Translate></NavLink>
                    <NavLink onClick={clickedMenu} to="/experience"><Translate>Experience</Translate></NavLink>
                    <NavLink onClick={clickedMenu} to="/design"><Translate>Design</Translate></NavLink>
                    <NavLink onClick={clickedMenu} to="/" exact><Translate>Code</Translate></NavLink>
                    <a href="https://capturedbyjan.art" target="_blank"><Translate>Photography</Translate></a>
                    {/* <NavLink onClick={clickedMenu} to="/blog"><Translate>Blog</Translate></NavLink> */}
                    <NavLink onClick={clickedMenu} to="/contact"><Translate>Contact</Translate></NavLink>
                </div>
                <div className={style.socials}>
                    <SocialIcon social="facebook" link="https://www.facebook.com/mager.jan" />
                    {/* <SocialIcon social="twitter" link="https://twitter.com/jan_mager" /> */}
                    <SocialIcon social="linkedin" link="https://www.linkedin.com/in/janmager/" />
                    <SocialIcon social="youtube" link="https://www.youtube.com/channel/UCgh4y7W172WGWwo5G7TLbwQ" />
                    <SocialIcon social="github" link="https://www.github.com/janmager" />
                    <img src={ln != 'pl' ? pl : en} className={style.langIcon} onClick={() => setLang(ln == 'pl' ? 'en' : 'pl')} />
                </div>
                <div className={style.hamburgerBox}>
                    <FontAwesomeIcon className={style.hamburgerIcon} icon={faBars} onClick={showResponsiveMenu} />
                </div>
            </div>
        </div>
    )
}

export default Navbar;
