import Title from "../../components/Title/Title";
// import style from './Home.module.css'
import Services from "../../components/Services/Services";
import AboutMeText from "../../components/AboutMeText/AboutMeText";
import Skills from "../../components/Skills/Skills";
import Languages from "../../components/Languages/Languages";

import Translate from "../../components/Translate/Translate";

function Mm(){
    return window.location.replace('https://capturedbyjan.art/mm')
}

export default Mm;