import React from 'react'
import Title from '../Title/Title'
import Translate from '../Translate/Translate'
import styles from './SkillsBubbles.module.css'
import Bubble from './Bubble/Bubble'

import tailwindLogo from '../../assets/techIcons/tailwind.webp'
import reactLogo from '../../assets/techIcons/react.webp'
import bootstrapLogo from '../../assets/techIcons/bootstrap.webp'
import nextLogo from '../../assets/techIcons/next.webp'
import vercelLogo from '../../assets/techIcons/vercel.webp'
import netlifyLogo from '../../assets/techIcons/netlify.webp'
import firebaseLogo from '../../assets/techIcons/firebase.svg'
import sanityLogo from '../../assets/techIcons/sanity.webp'
import photoshopLogo from '../../assets/techIcons/photoshop.webp'
import illustratorLogo from '../../assets/techIcons/illustrator.webp'
import premiereproLogo from '../../assets/techIcons/premierepro.webp'
import stripeLogo from '../../assets/techIcons/stripe.webp'
import gitLogo from '../../assets/techIcons/git.webp'
import bitbucketLogo from '../../assets/techIcons/bitbucket.webp'
import trelloLogo from '../../assets/techIcons/trello.webp'
import htmlLogo from '../../assets/techIcons/html.webp'
import cssLogo from '../../assets/techIcons/css.webp'
import jsLogo from '../../assets/techIcons/js.webp'
import sourcetreeLogo from '../../assets/techIcons/sourcetree.webp'
import jqueryLogo from '../../assets/techIcons/jquery.webp'
import daisyuiLogo from '../../assets/techIcons/daisyui.webp'
import pwaLogo from '../../assets/techIcons/pwa.webp'

const logos = [
    {
        alt: 'HTML',
        url: htmlLogo
    },
    {
        alt: 'CSS',
        url: cssLogo
    },
    {
        alt: 'JavaScript',
        url: jsLogo
    },,
    {
        alt: 'jQuery',
        url: jqueryLogo
    },
    {
        alt: 'ReactJS',
        url: reactLogo
    },
    {
        alt: 'NextJS',
        url: nextLogo
    },
    {
        alt: 'TailwindCSS',
        url: tailwindLogo
    },
    {
        alt: 'Bootstrap',
        url: bootstrapLogo
    },
    {
        alt: 'DaisyUI',
        url: daisyuiLogo
    },
    {
        alt: 'Vercel',
        url: vercelLogo
    },
    {
        alt: 'Netlify',
        url: netlifyLogo
    },
    {
        alt: 'Firebase',
        url: firebaseLogo
    },
    {
        alt: 'Sanity.io',
        url: sanityLogo
    },
    {
        alt: 'Stripe',
        url: stripeLogo
    },
    {
        alt: 'Adobe Photoshop',
        url: photoshopLogo
    },
    {
        alt: 'Adobe Illustrator',
        url: illustratorLogo
    },
    {
        alt: 'Adobe PremierePro',
        url: premiereproLogo
    },
    {
        alt: 'Git',
        url: gitLogo
    },
    {
        alt: 'Trello',
        url: trelloLogo
    },
    {
        alt: 'Bitbucket',
        url: bitbucketLogo
    },
    {
        alt: 'SourceTree',
        url: sourcetreeLogo
    },
    {
        alt: 'PWA',
        url: pwaLogo
    }
]

function SkillsBubbles() {
  return (
    <div>
        <Title text={<Translate>What technologies do I use?</Translate>} />
        <div className={styles.bubblesBox}>
            { logos.map(bubble => <Bubble logo={bubble.url} alt={bubble.alt} />) }    
        </div>
    </div>
  )
}

export default SkillsBubbles