import style from './Photo.module.css'
import React from 'react';

import Title from "../../components/Title/Title"
import { useState } from 'react'
import YtVideos from '../../components/YtVideos/YtVideos'

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import { Gallery, Item } from 'react-photoswipe-gallery'

import foto1 from '../../assets/photoPortfolio/01.jpg'
import foto2 from '../../assets/photoPortfolio/02.jpg'
import foto3 from '../../assets/photoPortfolio/03.jpg'
import foto4 from '../../assets/photoPortfolio/04.jpg'
import foto5 from '../../assets/photoPortfolio/05.jpg'
import foto6 from '../../assets/photoPortfolio/06.jpg'
import foto7 from '../../assets/photoPortfolio/07.jpg'
import foto8 from '../../assets/photoPortfolio/08.jpg'
import foto9 from '../../assets/photoPortfolio/09.jpg'
import foto10 from '../../assets/photoPortfolio/10.jpg'
import foto11 from '../../assets/photoPortfolio/11.jpg'
import foto12 from '../../assets/photoPortfolio/12.jpg'
import foto13 from '../../assets/photoPortfolio/13.jpg'
import foto14 from '../../assets/photoPortfolio/14.jpg'
import foto15 from '../../assets/photoPortfolio/15.jpg'
import foto16 from '../../assets/photoPortfolio/16.jpg'
import foto17 from '../../assets/photoPortfolio/17.jpg'
import foto18 from '../../assets/photoPortfolio/18.jpg'
import foto19 from '../../assets/photoPortfolio/19.jpg'
import foto20 from '../../assets/photoPortfolio/20.jpg'
import foto21 from '../../assets/photoPortfolio/21.jpg'
import foto22 from '../../assets/photoPortfolio/22.jpg'
import foto23 from '../../assets/photoPortfolio/23.jpg'
import foto24 from '../../assets/photoPortfolio/24.jpg'
import foto25 from '../../assets/photoPortfolio/25.jpg'
import foto26 from '../../assets/photoPortfolio/26.jpg'
import foto27 from '../../assets/photoPortfolio/27.jpg'
import foto28 from '../../assets/photoPortfolio/28.jpg'
import foto29 from '../../assets/photoPortfolio/29.jpg'
import foto30 from '../../assets/photoPortfolio/30.jpg'
import foto31 from '../../assets/photoPortfolio/31.jpg'
import foto32 from '../../assets/photoPortfolio/32.jpg'
import foto33 from '../../assets/photoPortfolio/33.jpg'
import foto34 from '../../assets/photoPortfolio/34.jpg'
import foto35 from '../../assets/photoPortfolio/35.jpg'
import foto36 from '../../assets/photoPortfolio/36.jpg'
import foto37 from '../../assets/photoPortfolio/37.jpg'
import foto38 from '../../assets/photoPortfolio/38.jpg'
import foto39 from '../../assets/photoPortfolio/39.jpg'
import foto40 from '../../assets/photoPortfolio/40.jpg'
import foto41 from '../../assets/photoPortfolio/41.jpg'
import foto42 from '../../assets/photoPortfolio/42.jpg'
import foto43 from '../../assets/photoPortfolio/43.jpg'
import foto44 from '../../assets/photoPortfolio/44.jpg'
import foto45 from '../../assets/photoPortfolio/45.jpg'
import foto46 from '../../assets/photoPortfolio/46.jpg'
import foto47 from '../../assets/photoPortfolio/47.jpg'
import foto48 from '../../assets/photoPortfolio/48.jpg'
import foto49 from '../../assets/photoPortfolio/49.jpg'
import foto50 from '../../assets/photoPortfolio/50.jpg'
import foto51 from '../../assets/photoPortfolio/51.jpg'

function Photo(){
    const photos = [
        {
            title: 'Jesień',
            url: foto1,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Wiosna',
            url: foto2,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Zima',
            url: foto3,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Wrocław',
            url: foto49,
            width: 1080,
            height: 1440,
            rotate: true
        },
        {
            title: 'Jesień',
            url: foto4,
            width: 1080,
            height: 1440,
            rotate: true
        },
        {
            title: 'Most',
            url: foto14,
            width: 1080,
            height: 1440,
            rotate: true
        },
        {
            title: 'Most',
            url: foto13,
            width: 1080,
            height: 1440,
            rotate: true
        },
        {
            title: 'Blok',
            url: foto15,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Zima',
            url: foto11,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Mostek',
            url: foto12,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Most',
            url: foto22,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Samoloty',
            url: foto23,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Most',
            url: foto24,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Zima',
            url: foto10,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Tory',
            url: foto5,
            width: 1080,
            height: 1440,
            rotate: true
        },
        {
            title: 'Tory',
            url: foto6,
            width: 1080,
            height: 1440,
            rotate: true
        },
        {
            title: 'Zima',
            url: foto7,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Zima',
            url: foto8,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Zima',
            url: foto9,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Natura',
            url: foto28,
            width: 1080,
            height: 1440,
            rotate: true
        },
        {
            title: 'Miasto',
            url: foto16,
            width: 1080,
            height: 1440,
            rotate: true
        },
        {
            title: 'Miasto',
            url: foto17,
            width: 1080,
            height: 1440,
            rotate: true
        },
        {
            title: 'Miasto',
            url: foto18,
            width: 1080,
            height: 1440,
            rotate: true
        },
        {
            title: 'Pociag',
            url: foto19,
            width: 1080,
            height: 1440,
            rotate: true
        },
        {
            title: 'Natura',
            url: foto20,
            width: 1080,
            height: 1440,
            rotate: true
        },
        {
            title: 'Natura',
            url: foto21,
            width: 1080,
            height: 1440,
            rotate: true
        },
        {
            title: 'Most',
            url: foto25,
            width: 1080,
            height: 1440,
            rotate: true
        },
        {
            title: 'Natura',
            url: foto26,
            width: 1080,
            height: 1440,
            rotate: true
        },
        {
            title: 'Las',
            url: foto27,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Las',
            url: foto29,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Las',
            url: foto30,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Las',
            url: foto31,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Natura',
            url: foto32,
            width: 1080,
            height: 1440,
            rotate: true
        },
        {
            title: 'Samochody',
            url: foto33,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Samoloty',
            url: foto34,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Samochody',
            url: foto35,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Tory',
            url: foto36,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Boisko',
            url: foto37,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Zachód',
            url: foto38,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Zima',
            url: foto39,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Las',
            url: foto40,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Las',
            url: foto41,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Jezioro',
            url: foto42,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Jezioro',
            url: foto43,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Jezioro',
            url: foto44,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Jezioro',
            url: foto45,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Natura',
            url: foto46,
            width: 1080,
            height: 1440,
            rotate: true
        },
        {
            title: 'Jezioro',
            url: foto47,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Mostek',
            url: foto48,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Wrocław',
            url: foto50,
            width: 1440,
            height: 1080,
            rotate: false
        },
        {
            title: 'Wrocław',
            url: foto51,
            width: 1080,
            height: 1440,
            rotate: true
        },
    ]

    const videos = [
        {
            title: "🌅 Chill Sunset Drone Ride",
            date: '10.08.2022',
            url: "https://www.youtube.com/embed/vx2hzT6HC50",
            place: "Polska, Puszcza Zielonka"
        },
        {
            title: "🏰 Wroclaw From The Sky",
            date: '31.01.2022',
            url: "https://www.youtube.com/embed/7mZQR4z3daU",
            place: "Polska, Wrocław"
        },
        {
            title: "⛅ Cloudy Days",
            date: '22.12.2020',
            url: "https://www.youtube.com/embed/vsFQ1JpHvxs",
            place: "Polska, Poznań"
        },
        {
            title: "🍿 Chill Drone Video",
            date: '22.04.2021',
            url: "https://www.youtube.com/embed/eLtFtRyKrl0",
            place: "Polska, Poznań"
        },
        {
            title: "❄️ Winter Magic",
            date: '14.01.2021',
            url: "https://www.youtube.com/embed/GlXyB9h94T8",
            place: "Polska, Poznań"
        },
        {
            title: "⛰️ Light of Nature",
            date: '15.08.2021',
            url: "https://www.youtube.com/embed/2xbVQ8TWjho",
            place: "Polska, Podlasie"
        },
        {
            title: "Survival Race 2019 Poznań",
            date: '12.06.2019',
            url: "https://www.youtube.com/embed/qIxNbtWpnwM",
            place: "Polska, Poznań"
        },
        {
            title: "GoPro Snowboard Edit",
            date: '01.02.2020',
            url: "https://www.youtube.com/embed/5ldEtcean3k",
            place: "Czechy, Harrachov"
        },
    ]

    const [limit, setLimit] = useState(10);
    const [limitActive, setLimitActive] = useState(true);

    function loadMoreItems(count){
        setLimit(limit + count);
        if(limit + count > photos.length) setLimitActive(false);
    }

    return(
        <div>
            <Title text="Galeria zdjęć" />
            <div className={style.photoBox}>
                <Gallery shareButton={false} fullscreenButton={false} zoomButton={false}>
                    {
                        photos.map((img, index) => {
                            if(index < limit){
                                return(
                                    <Item key={index} original={img.url} thumbnail={img.url} height={img.height} width={img.width}>
                                        {({ ref, open }) => (
                                            <img ref={ref} onClick={open} src={img.url} alt={img.title} />
                                        )}
                                    </Item>
                                )
                            }
                        })
                    }
                </Gallery>
            </div>
            {
                limitActive ? 
                <div className={style.loadMoreBtnWrapper}>
                    <div onClick={() => loadMoreItems(10)} className={style.loadMoreBtn}>Załaduj więcej</div>
                    <div onClick={() => loadMoreItems(photos.length)} className={style.loadMoreBtn}>Załaduj wszystko</div>
                </div> : null
            }
            <div className={style.videoBox}>
                <Title text="Galeria filmów" />
                <YtVideos videos={videos} />
            </div>
        </div>
    )
}

export default Photo