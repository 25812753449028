import style from './YtVideo.module.css'
import React from 'react';

function YtVideo({url, title, place, date}){
    return(
        <div className={style.ytVideo}>
            <div className={style.ytVideoInfos}>
                <span className={style.ytVideoTitle}>{title}</span>
                <span className={style.ytVideoPlace}>{place}</span>
                <span className={style.ytVideoDate}>{date}</span>
            </div>
            <iframe width="100%" height="245px" src={url} title={title} allowFullScreen></iframe>
        </div>
    )
}

export default YtVideo;