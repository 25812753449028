import NewCodePortfolioItem from '../NewCodePortfolioItem/NewCodePortfolioItem';
import style from './CodePortfolio.module.css'
import ItemCodePortfolio from './ItemCodePortfolio/ItemCodePortfolio'
import React from 'react';

function CodePortfolio({codes, newCodes}){
    return(
        <>
            <div className={style.NewCodePortfolioBox}>
                {
                    newCodes.map(code => {
                        return(
                            <NewCodePortfolioItem code={code} />
                        )
                    })
                }
            </div>
            {/* <hr />
            <div className={style.CodePortfolioBox}>
                {
                    codes.map(code => {
                        return(
                            <ItemCodePortfolio code={code} />
                        )
                    })
                }
            </div> */}
        </>
        
    )
}

export default CodePortfolio