import style from './Footer.module.css'
import { useState, useEffect } from 'react'
import React from 'react';

import { Link } from 'react-router-dom';
import Translate from '../Translate/Translate';

function Footer(){
    const [currentYear, setCurrentYear] = useState('1999');

    useEffect(() => {
        let date = new Date();
        let year = date.getFullYear();
        setCurrentYear(year)
    }, [])

    return(
        <div className={style.footer}>
            <span>Copyright &copy; <span className={style.spanLight}>Jan Mager</span> 2013 - {currentYear}</span>
            <span><Translate>Project and realization</Translate> - <span className={style.spanLight}><Link to="/">janmager.pl</Link></span></span>
        </div>
    )
}

export default Footer;