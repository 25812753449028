import React, { useState } from 'react'
import style from './GalleryImage.module.css'

function GalleryImage({src, alt}) {
    const [ show, setShow ] = useState()

    return (
        <div onClick={() => setShow(!show)} className={style.imageGalleryContainer}>
            <img style={{width: '100%'}} src={src} alt={alt} />
            
            {show ? <div className={style.imageGalleryBackdrop}>
                <img src={src} alt={alt} />
            </div> : ''}
        </div>
    )
}

export default GalleryImage