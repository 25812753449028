import YtVideo from '../YtVideo/YtVideo'
import style from './YtVideos.module.css'
import React from 'react';

function YtVideos({videos}){
    return(
        <div className={style.videosInside}>  
            {
                videos.map((video, index) => {
                    return(
                        <YtVideo key={index} title={video.title} place={video.place} url={video.url} date={video.date} />
                    )
                })
            }   
        </div>
    )
}

export default YtVideos