import Certificate from './Certificate/Certificate';
import style from './Certificates.module.css'
import React from 'react';

function Certificates({certificates}){
    return(
        <div className={style.certificates}>
            {
                certificates.map(cer => {
                    return(
                        <Certificate title={cer.title} desc={cer.desc} icon={cer.icon} date={cer.date} from={cer.from} />
                    )
                })
            }
        </div>
    )
}

export default Certificates;