import style from './AboutMeText.module.css'
import { Link } from 'react-router-dom';
import React from 'react';

import Translate from '../Translate/Translate'

function AboutMeText(){
    return(
        <div>
            <p className={style.about}>
                <Translate>Hi there! 👋 I have been creating modern web applications for over 8 years and working professionally for over 3 years. I pay attention to the smallest details in my projects and use the most optimal solutions during the development process. I work with JavaScript, TypeScript, ReactJS, NextJS, Firebase, and Sanity. I would be happy to complete your project from A to Z - from graphic design to creating a fully functional product and final launch, as well as providing ongoing support. In my free time, I enjoy photography and producing videos using a drone.</Translate>
            </p>
            {/* <p className={style.about}>
                <Translate>Hi 👋 let me tell you a few words about me, I have been developing websites and web applications for over 7 years. I use technologies such as HTML, CSS, Bootstrap, JavaScript, ReactJS among others in my projects. I can create and administer MySQL and Firebase systems, as well as connect between applications via APIs. I carry out all assignments using up-to-date, most optimal technologies for the project, while ensuring cross-platform performance.</Translate>
            </p>
            <p className={style.about}>
                <Translate>I also do computer graphics professionally - designing logos, flyers, posters, advertising banners, social media graphics, website visuals and much more, there is no graphic design that is impossible for me. All work is created to the highest quality using current standards.</Translate>
            </p>
            <p className={style.about}>
                <Translate>I have been trusted by many companies, people running channels, social media profiles and organisers of various events. If you are looking for a person to whom you want to commission the creation of the whole project from A to Z (visual design, programming, implementation and management), and then receive a ready, fully functioning, modern-looking product, you've come to the right place. I invite you to take a look at my experience, portfolio and contact me via the form to establish the details of cooperation.</Translate>
            </p>
            <p className={style.about}>
                <Translate>In addition to programming and computer graphics, I am a hobbyist in photography, mainly drone photography and film editing. A portfolio of my photos can be seen at capturedbyjan.art. Examples of film productions, are available on my YouTube channel.</Translate>
            </p> */}
        </div>
    )
}

export default AboutMeText;
