import style from './SocialIcon.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faYoutube, faLinkedinIn, faGithub } from '@fortawesome/free-brands-svg-icons'
import React from 'react';

function SocialIcon({social, link}){

    return(
        <div className={style.icon}>
            <a href={link}>
            {
                social === 'facebook' ? <FontAwesomeIcon icon={faFacebookF} /> :
                social === 'twitter' ? <FontAwesomeIcon icon={faTwitter} /> :
                social === 'linkedin' ? <FontAwesomeIcon icon={faLinkedinIn} /> :
                social === 'github' ? <FontAwesomeIcon icon={faGithub} /> : 
                social === 'youtube' ? <FontAwesomeIcon icon={faYoutube} /> : null
            }
            </a>
        </div>
    )
}

export default SocialIcon;