import React from 'react'
import style from './NewCodePortfolioItem.module.css'
import Translate from '../Translate/Translate'
import { NavLink } from 'react-router-dom';

function NewCodePortfolioItem({code}) {
    return (
        <NavLink to={`/code/${code.slug}`} className={style.newItemBox}>
            <img className={style.newItemBox_mainImg} src={code.heroImg} width={'90%'} />
            <div className={style.newItemBox_content_bottom}>
                <img src={code.icon} height="42" width="42" />
                <div>
                    <span className={style.newItemBox_content_bottom_title}>{code.bigName}</span>
                    <span className={style.newItemBox_content_bottom_desc}><Translate>{code.smallName}</Translate></span>
                </div>
            </div>
            <img src={code.mainImg} className={style.newItemBox_mainImgBackdrop} />
        </NavLink>
    )
}

export default NewCodePortfolioItem