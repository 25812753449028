import { useEffect, useState } from 'react';
import Translate from '../../components/Translate/Translate';
import newCodePortfolio from '../../data/newCodePortfolio';
import { useParams } from 'react-router-dom';
import React from 'react';
import SingleCodeSidebar from '../../components/SingleCodeSidebar/SingleCodeSidebar';
import style from './SingleCode.module.css'
import { NavLink } from 'react-router-dom';
import { VscArrowSmallRight } from "react-icons/vsc";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

function SingleCode(){
    const [ currentCode, setCurrentCode ] = useState()
    const slug = useParams()
    const [ show, setShow ] = useState()

    useEffect(() => {
        let obj = newCodePortfolio.find(code => code.slug == slug.slug)
        if(obj) setCurrentCode(obj)
    }, [])

    if(currentCode) return(
        <div className={style.singlecodeOverlay}>
            <SingleCodeSidebar item={currentCode} />
            <div className={style.mainBoxOverlay}>
                <div className={style.crumb}>
                    <NavLink to="/">Portfolio</NavLink>
                    <span style={{fontSize: '14px', opacity: .15}}><MdKeyboardDoubleArrowRight  style={{marginBottom: '3px'}} /></span>
                    <p><Translate>{currentCode.bigName}</Translate></p>
                </div>
                <img className={style.icon} src={currentCode.icon} height={48} width={48} />
                <h1><Translate>{currentCode.bigName}</Translate></h1>
                <span><Translate>{currentCode.smallName}</Translate></span>
                <div className={style.mainImg}>
                    <img src={currentCode.mainImg} />
                    <div></div>
                </div>
                {currentCode.p1 ? 
                <div className={style.contentBlock} style={{marginTop: currentCode.mainImg ? '-75px' : ''}}>
                    <img src={currentCode.iphoneImg} />
                    <p>
                        <Translate>{currentCode.p1}</Translate>
                    </p>
                </div> : ''}
                {currentCode.p2 ? 
                <div className={`${style.contentBlock} ${style.contentBlock2}`} style={{marginTop: '25px'}}>
                    <p>
                        <Translate>{currentCode.p2}</Translate>
                    </p>
                    <img src={currentCode.macbookImg} style={{maxWidth: currentCode.mainImg ? '500px' : '300px'}} />
                </div> : ''}
            </div>
        </div>
    )
    else{
        return (
            <div></div>
        )
    }
}

export default SingleCode;
