import React from 'react';
import { useState } from 'react';
import style from './PopUp.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react';

function PopUp({text, time, close, closePopupFun}){
    
    return(
        <div className={style.popUpBox}>
            <span className={style.popUpText}>
                {
                    close ? <FontAwesomeIcon icon={faTimesCircle} className={style.closePopUp} onClick={() => closePopupFun(false)} /> : null
                }
                {text}
            </span>
        </div>
    )
}

export default PopUp;