import React, { useRef, useState, useContext } from 'react'
import emailjs from 'emailjs-com'

import style from './Contact.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faAt, faPaperPlane, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { LangContext } from '../../App'

import Title from '../../components/Title/Title'
import PopUp from '../../components/PopUp/PopUp'
import Translate from '../../components/Translate/Translate'

function Contact(){
    const form = useRef('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [topic, setTopic] = useState('');
    const [message, setMessage] = useState('');

    const [popUp, setPopUp] = useState(false);

    const [sending, setSending] = useState(false);

    const [popUpError, setPopUpError] = useState(false);
    const langCurrent = useContext(LangContext)

    function closeSendSuccess(visible){
        setPopUp(visible);
    }

    function closeSendError(visible){
        setPopUpError(visible);
    }

    function sendEmail(e){
        e.preventDefault();
        setSending(true);
        emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_USER_ID)
        .then((result) => {
            setName('');
            setEmail('');
            setTopic('');
            setMessage('');
            setPopUp(true);
            setSending(false);
        }, (error) => {
            console.log(error.text);
        })
    }

    return(
        <div>
            <Title text={<Translate>Write to me</Translate>} />
            <form ref={form} onSubmit={sendEmail} className={style.contactForm}>
                <div className={style.formRowFirst}>
                    <div className={style.formRow}>
                        <label><FontAwesomeIcon icon={faUser} /></label>
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} name="name_from" placeholder={`${langCurrent == 'pl' ? 'Twoje imię' : 'Your name'}`} autoComplete="off" required />
                    </div>
                    <div className={style.formRow}>
                        <label htmlFor="email_from"><FontAwesomeIcon icon={faAt} /></label>
                        <input type="email" value={email} name="email_from" onChange={(e) => setEmail(e.target.value)} placeholder={`${langCurrent == 'pl' ? 'Twój email' : 'Your email'}`} autoComplete="off" required />
                    </div>
                </div>
                <div className={style.formRow}>
                    <label><FontAwesomeIcon icon={faPaperPlane} /></label>
                    <input type="text" value={topic} name="topic" autoComplete="off" onChange={(e) => setTopic(e.target.value)} placeholder={`${langCurrent == 'pl' ? 'Temat twojej wiadomości' : 'Topic of your message'}`} required />
                </div>
                <div className={style.formRow}>
                    <label><FontAwesomeIcon icon={faEnvelope} /></label>
                    <textarea name="message" value={message} placeholder={`${langCurrent == 'pl' ? 'Treść twojej wiadomości' : 'Content of your message'}`} onChange={(e) => setMessage(e.target.value)} required rows='5' minLength={10}></textarea>
                </div>
                <div className={style.buttonWrapper}>
                    <button type="submit"><Translate>Send message</Translate></button>
                </div>
            </form>
            {
                popUp ? 
                <PopUp closePopupFun={closeSendSuccess} close={true} text={<Translate>The message was sent correctly! You have received a copy of it at your email address. I will try to reply to it within 24 hours. Until we hear from you!</Translate>} time="5" /> 
                : null
            }
            {
                sending ? 
                <PopUp text={<Translate>Sending...</Translate>} close={false} /> 
                : null
            }
            {
                popUpError ? 
                <PopUp closePopupFun={closeSendError} text={<Translate>An error occurred while sending the message. Contact me previous email: jan@mager.tech</Translate>} close={true} /> 
                : null
            }
        </div>
    )
}

export default Contact;