import CodePortfolio from '../../components/CodePortfolio/CodePortfolio';
import Title from '../../components/Title/Title'
import Translate from '../../components/Translate/Translate';
import codePortfolio from '../../data/codePortfolio'
import newCodePortfolio from '../../data/newCodePortfolio'
import React from 'react';

function Code(){
    return(
        <div>
            <Title text={<Translate>Application Portfolio</Translate>} />
            <CodePortfolio codes={codePortfolio} newCodes={newCodePortfolio} />
        </div>
    )
}

export default Code;
