import React from 'react'

function Bubble(props) {
  return (
    <div>
        <img src={props.logo} alt={props.alt} height="30" width='30' />
        <span>{props.alt}</span>
    </div>
  )
}

export default Bubble