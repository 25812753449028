import style from './Title.module.css'
import React from 'react';

function Title({text}){
    return(
        <div className={style.titleContainer}>
            <h2 className={style.title}>{text}</h2>
        </div>
    )
}

export default Title