import react from '../assets/techIcons/react.webp'
import leaflet from '../assets/techIcons/leaflet.png'
import nextjs from '../assets/techIcons/next.webp'
import sanity from '../assets/techIcons/sanity.webp'
import stripe from '../assets/techIcons/stripe.webp'
import tailwind from '../assets/techIcons/tailwind.webp'
import supabase from '../assets/techIcons/supabase.png'
import tailwindUi from '../assets/techIcons/tailwind-ui.png'
import chartJs from '../assets/techIcons/chartjs.png'
import authJs from '../assets/techIcons/auth-js.png'
import framerMotion from '../assets/techIcons/framer-motion.png'
import tmdb from '../assets/techIcons/tmdb.png'
import firebase from '../assets/techIcons/firebase.svg'
import prisma from '../assets/techIcons/prisma.png'
import resend from '../assets/techIcons/resend.png'
import payloadCms from '../assets/techIcons/payloadcms.svg'
import bootstrap from '../assets/techIcons/bootstrap.webp'
import nodemailer from '../assets/techIcons/nodemailer.png'
import apiFootball from '../assets/techIcons/api-football.png'
import nodejs from '../assets/techIcons/nodejs.png'
import kinde from '../assets/techIcons/kinde.png'
import coingecko from '../assets/techIcons/coingecko.png'
import cmc from '../assets/techIcons/cmc.png'

export const tech_img = {
    'React.js' : react,
    'LeafletMap' : leaflet,
    'Next.js' : nextjs,
    'Sanity.io' : sanity,
    'Stripe' : stripe,
    'Tailwind.css' : tailwind,
    'Supabase' : supabase,
    'Tailwind UI' : tailwindUi,
    'Chart.js' : chartJs,
    'Auth.js' : authJs,
    'Framer Motion' : framerMotion,
    'TMDB API' : tmdb,
    'Firebase' : firebase,
    'Prisma' : prisma,
    'Payload CMS' : payloadCms,
    'Resend' : resend,
    'Bootstrap' : bootstrap,
    'Nodemailer' : nodemailer,
    'API Football' : apiFootball,
    'Node.js' : nodejs,
    'Kinde' : kinde,
    'Coingecko API' : coingecko,
    'CoinMarketCap API' : cmc,
}