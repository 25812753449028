import style from './ProfileInformation.module.css'
import React from 'react';

function ProfileInformation({label, content}){
    return(
        <div className={style.infoBox}>
            <span className={style.label}>{label}:</span>
            <span className={style.content}>{content}</span>
        </div>
    )
}

export default ProfileInformation;