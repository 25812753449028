import style from './HistoryEducation.module.css'
import Title from '../Title/Title'
import ExperienceStep from '../ExperienceStep/ExperienceStep'
import Translate from '../../components/Translate/Translate';
import React from 'react';


function HistoryEducation({history}){
    return(
        <div className={style.eduBox}>
            <Title text={<Translate>Education</Translate>} />
            <div className={style.eduInside}>
                {
                    history.map(his => {
                        return(
                            <ExperienceStep marginTopZero={true} groupBy={his.groupBy} durationFrom={his.fromTimestamp} durationTo={his.toTimestamp} title={his.title} subtitle={his.subtitle} position={his.position} years={his.years} icon={his.icon} />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default HistoryEducation