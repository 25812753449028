import Title from "../../components/Title/Title";
// import style from './Home.module.css'
import React from 'react';
import Services from "../../components/Services/Services";
import AboutMeText from "../../components/AboutMeText/AboutMeText";
import Skills from "../../components/Skills/Skills";
import Languages from "../../components/Languages/Languages";

import Translate from "../../components/Translate/Translate";
import SkillsBubbles from "../../components/SkillsBubbles/SkillsBubbles";

function Home(){
    return(
        <div>
            <Title text={<Translate>About me</Translate>} />
            <AboutMeText />
            <Title text={<Translate>What is my business?</Translate>} />
            <Services />
            {/* <Skills /> */}
            <SkillsBubbles />
            {/* <Title text={<Translate>What languages do I know?</Translate>} /> */}
            {/* <Languages /> */}
        </div>
    )
}

export default Home;