import style from './Certificate.module.css'
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDesktop, faNetworkWired, faLaptopCode } from '@fortawesome/free-solid-svg-icons'
import Translate from '../../Translate/Translate'

function Certificate({title, desc, date, icon, from}){
    return(
        <div className={style.certiciateBox}>
            <div className={style.icon}>
                {
                    icon === 'pc' ? <FontAwesomeIcon icon={faDesktop} /> : 
                    icon === 'net' ? <FontAwesomeIcon icon={faNetworkWired} /> : 
                    icon === 'code' ? <FontAwesomeIcon icon={faLaptopCode} /> : null
                }
            </div>
            <div className={style.content}>
                <span className={style.title}>{title}<span className={style.date}>{date}</span><span className={style.from}><Translate>Issued by</Translate>: <span className={style.fromName}>{from}</span></span></span>
                <span className={style.desc}>{desc}</span>
            </div>
        </div>
    )
}

export default Certificate