import { useContext } from 'react';
import { useEffect, useState } from 'react';

import { LangContext } from '../../App'

import pl from '../../languages/pl'


const translate = (text) => {
    
    return pl[text] || text
}

const Translate = ({ children }) => {    
    const langCurrent = useContext(LangContext)

    return langCurrent == 'en' ? children : translate(children);
};

export default Translate;