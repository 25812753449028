import Service from './Service/Service';
import style from './Services.module.css'
import React from 'react';

import Translate from '../Translate/Translate'

function Services(){
    return(
        <div className={style.servicesBox}>
            <Service title={<Translate>Programming</Translate>} icon="code" desc={<Translate>Web applications, websites, shops</Translate>}/>
            <Service title={<Translate>Design</Translate>} icon="design" desc={<Translate>Logos, banners, ads, application UI</Translate>} />
            <Service title={<Translate>Drone Pilot</Translate>} icon="drone" desc={<Translate>Drone video and photo</Translate>} />
            {/* <Service title={<Translate>Montage</Translate>} icon="video" desc={<Translate>Editing and montage of films, commercials.</Translate>} /> */}
        </div>
    )
}

export default Services;