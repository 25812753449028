import style from './Design.module.css'
import Title from '../../components/Title/Title'
import { useState } from 'react'
import Translate from '../../components/Translate/Translate'

import jpg1 from '../../assets/designPortfolio/01.jpg'
import jpg2 from '../../assets/designPortfolio/02.jpg'
import jpg3 from '../../assets/designPortfolio/03.jpg'
import jpg4 from '../../assets/designPortfolio/04.jpg'
import jpg5 from '../../assets/designPortfolio/05.jpg'
import jpg6 from '../../assets/designPortfolio/06.jpg'
import jpg7 from '../../assets/designPortfolio/07.jpg'
import jpg8 from '../../assets/designPortfolio/08.jpg'
import jpg9 from '../../assets/designPortfolio/09.jpg'
import jpg10 from '../../assets/designPortfolio/10.jpg'
import jpg11 from '../../assets/designPortfolio/11.jpg'
import jpg12 from '../../assets/designPortfolio/12.jpg'
import jpg13 from '../../assets/designPortfolio/13.jpg'
import jpg14 from '../../assets/designPortfolio/14.jpg'
import jpg15 from '../../assets/designPortfolio/15.jpg'
import jpg16 from '../../assets/designPortfolio/16.jpg'
import jpg17 from '../../assets/designPortfolio/17.jpg'
import jpg18 from '../../assets/designPortfolio/18.jpg'
import jpg19 from '../../assets/designPortfolio/19.jpg'
import jpg20 from '../../assets/designPortfolio/20.jpg'
import jpg21 from '../../assets/designPortfolio/21.jpg'
import jpg22 from '../../assets/designPortfolio/22.jpg'
import jpg23 from '../../assets/designPortfolio/23.jpg'
import jpg24 from '../../assets/designPortfolio/24.jpg'
import jpg25 from '../../assets/designPortfolio/25.jpg'
import jpg26 from '../../assets/designPortfolio/26.jpg'
import jpg27 from '../../assets/designPortfolio/27.jpg'
import jpg28 from '../../assets/designPortfolio/28.jpg'
import jpg29 from '../../assets/designPortfolio/29.jpg'
import jpg30 from '../../assets/designPortfolio/30.jpg'
import jpg31 from '../../assets/designPortfolio/31.jpg'
import jpg32 from '../../assets/designPortfolio/32.jpg'
import jpg33 from '../../assets/designPortfolio/33.jpg'
import jpg34 from '../../assets/designPortfolio/34.jpg'
import jpg35 from '../../assets/designPortfolio/35.jpg'
import jpg36 from '../../assets/designPortfolio/36.jpg'
import jpg37 from '../../assets/designPortfolio/37.jpg'
import jpg38 from '../../assets/designPortfolio/38.jpg'
import jpg39 from '../../assets/designPortfolio/39.jpg'
import jpg40 from '../../assets/designPortfolio/40.jpg'
import jpg41 from '../../assets/designPortfolio/41.jpg'
import jpg42 from '../../assets/designPortfolio/42.jpg'
import jpg43 from '../../assets/designPortfolio/43.jpg'
import jpg44 from '../../assets/designPortfolio/44.jpg'
import jpg45 from '../../assets/designPortfolio/45.jpg'
import jpg46 from '../../assets/designPortfolio/46.jpg'
import jpg47 from '../../assets/designPortfolio/47.jpg'
import jpg48 from '../../assets/designPortfolio/48.jpg'
import jpg49 from '../../assets/designPortfolio/49.jpg'
import jpg50 from '../../assets/designPortfolio/50.jpg'
import jpg51 from '../../assets/designPortfolio/51.jpg'
import jpg52 from '../../assets/designPortfolio/52.jpg'
import jpg53 from '../../assets/designPortfolio/53.jpg'
import jpg54 from '../../assets/designPortfolio/54.jpg'
import jpg55 from '../../assets/designPortfolio/55.jpg'
import jpg56 from '../../assets/designPortfolio/56.jpg'
import jpg57 from '../../assets/designPortfolio/57.jpg'
import jpg58 from '../../assets/designPortfolio/58.jpg'
import jpg59 from '../../assets/designPortfolio/59.jpg'
import jpg60 from '../../assets/designPortfolio/60.jpg'
import jpg61 from '../../assets/designPortfolio/61.jpg'
import jpg62 from '../../assets/designPortfolio/62.jpg'
import jpg63 from '../../assets/designPortfolio/63.jpg'
import jpg64 from '../../assets/designPortfolio/64.jpg'
import jpg65 from '../../assets/designPortfolio/65.jpg'
import jpg66 from '../../assets/designPortfolio/66.jpg'
import jpg67 from '../../assets/designPortfolio/67.jpg'
import jpg68 from '../../assets/designPortfolio/68.jpg'
import jpg69 from '../../assets/designPortfolio/69.jpg'
import jpg70 from '../../assets/designPortfolio/70.jpg'

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import React from 'react';

import { Gallery, Item } from 'react-photoswipe-gallery'
import GalleryImage from '../../components/GalleryImage/GalleryImage'

function Design(){
    const images = [
        {
            title: 'Logo GłodnySkaner',
            url: jpg70,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo DietBall',
            url: jpg65,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Typerka',
            url: jpg67,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo FinanceBay.io',
            url: jpg68,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Ogrodzenia Betonowe Ciszewski',
            url: jpg64,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Adam Tarunt',
            url: jpg57,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Twoje Polonistki',
            url: jpg58,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Wigo',
            url: jpg18,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Gruby Benek',
            url: jpg3,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Cross Minded',
            url: jpg47,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo QQrydziana Budka',
            url: jpg22,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo szalikowo.pl',
            url: jpg66,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Ogólnopolski Turniej Kucharski',
            url: jpg19,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Egera',
            url: jpg63,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo YogaBazar',
            url: jpg16,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Pixels Interactive',
            url: jpg46,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo CapturedByJan',
            url: jpg69,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Farma Cioci Stasi',
            url: jpg6,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo FitStop',
            url: jpg7,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo GetCar',
            url: jpg8,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Alogy',
            url: jpg5,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Dev Hunt',
            url: jpg2,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Krystian Wojewoda',
            url: jpg9,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo ZakupyChemiczne.pl',
            url: jpg10,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Easy Win',
            url: jpg1,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo LuckyLood',
            url: jpg11,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Peyt',
            url: jpg12,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Susesi',
            url: jpg13,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Alamr Premium Real Estate',
            url: jpg4,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo SmartWash',
            url: jpg14,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo telemag.pl',
            url: jpg15,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo YogaBazar',
            url: jpg17,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo AudioVideoShow 2017',
            url: jpg20,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Gruby Benek',
            url: jpg21,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Muscle Care',
            url: jpg23,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Abstra',
            url: jpg24,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo CSGO 4Ever',
            url: jpg25,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Just Healthy Life Style',
            url: jpg26,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Mobilna Strefa Urody',
            url: jpg27,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Sailor Simulator',
            url: jpg28,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo StepByStep',
            url: jpg29,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo zyskownysklep.pl',
            url: jpg30,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Po Drugiej Stronię Tęczy',
            url: jpg42,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Szansa na Sukces',
            url: jpg48,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo DTE',
            url: jpg54,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Hell Wing',
            url: jpg55,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Logo Bonkers',
            url: jpg56,
            width: 730,
            height: 730,
            tags: ['logo']
        },
        {
            title: 'Książka Sebastian Jakubowski',
            url: jpg31,
            width: 730,
            height: 730,
            tags: ['book']
        },
        {
            title: 'Książka Konrad Gandera',
            url: jpg32,
            width: 730,
            height: 730,
            tags: ['book']
        },
        {
            title: 'Książka Konrad Gandera',
            url: jpg33,
            width: 730,
            height: 730,
            tags: ['book']
        },
        {
            title: 'Książka Konrad Gandera',
            url: jpg34,
            width: 730,
            height: 730,
            tags: ['book']
        },
        {
            title: 'Książka Konrad Gandera',
            url: jpg45,
            width: 730,
            height: 730,
            tags: ['book']
        },
        {
            title: 'Snapchat Czerwonak',
            url: jpg35,
            width: 730,
            height: 730,
            tags: ['snapchat']
        },
        {
            title: 'Snapchat Koziegłowy',
            url: jpg36,
            width: 730,
            height: 730,
            tags: ['snapchat']
        },



        
        {
            title: 'Wizytówka Majówka w SQ',
            url: jpg43,
            width: 730,
            height: 730,
            tags: ['business card']
        },
        {
            title: 'Wizytówka Just Healthy Life Style',
            url: jpg38,
            width: 730,
            height: 730,
            tags: ['business card']
        },
        {
            title: 'Ulotka Just Healthy Life Style',
            url: jpg39,
            width: 730,
            height: 730,
            tags: ['leaflet']
        },
        
        {
            title: 'Reklama nazwa.pl',
            url: jpg59,
            width: 730,
            height: 730,
            tags: ['advertisement']
        },
        {
            title: 'Reklama nazwa.pl',
            url: jpg60,
            width: 730,
            height: 730,
            tags: ['advertisement']
        },
        {
            title: 'Certyfikat',
            url: jpg61,
            width: 730,
            height: 730,
            tags: ['leaflet']
        },
    ]

    const categories = ['all', 'logo', 'poster', 'leaflet', 'book', 'banner', 'advertisement', 'business card', 'snapchat'];

    const [currentGallery, setCurrentGallery] = useState(images);
    const [currentCategory, setCurrentCategory] = useState('wszystko');
    const [limit, setLimit] = useState(24);
    const [limitActive, setLimitActive] = useState(true);

    function chooseCategory(name){
        setLimit(12);
        setLimitActive(true);
        setCurrentCategory(name);

        if(name==='all'){
            setCurrentGallery(images);
        }
        else{
            let newGallery = []
            images.map((img) => {
                img.tags.map(cat => {
                    if(cat === name){
                        newGallery.push(img);
                    }
                })
            })
            setCurrentGallery(newGallery);
            if(newGallery.length < limit){
                setLimitActive(false);
            }
            else{
                setLimitActive(true);
            }
        }
    }

    function loadMoreItems(count){
        setLimit(limit + count);
        if(limit + count > images.length) setLimitActive(false);
    }

    return(
        <div>
            <Title text={<Translate>Graphics portfolio</Translate>} />
            {/* <div className={style.chooseCategoryBox}>
                {
                    categories.map((cat, index) => {
                        return(
                            <span key={index} className={currentCategory === cat ? style.activeCat : ''} onClick={() => chooseCategory({cat}.cat)}><Translate>{cat}</Translate></span>
                        )
                    })
                }
            </div> */}
            <div className={style.portfolioBox}>
                {
                    currentGallery.map((img, index) => {
                        return(
                            <GalleryImage src={img.url} alt={img.title} />
                        )
                    })
                }
                {/* <Gallery shareButton={false} fullscreenButton={false} zoomButton={false}>
                    {
                        currentGallery.map((img, index) => {
                            if(index < limit){
                                return(
                                    <Item key={index} original={img.url} thumbnail={img.url} height={img.height} width={img.width}>
                                        {({ ref, open }) => (
                                            <img ref={ref} onClick={open} width='140' height='140' src={img.url} alt={img.title} />
                                        )}
                                    </Item>
                                )
                            }
                        })
                    }
                </Gallery> */}
            </div>
            {/*
                limitActive ? 
                <div className={style.loadMoreBtnWrapper}>
                    <div onClick={() => loadMoreItems(12)} className={style.loadMoreBtn}><Translate>Load more</Translate></div>
                    <div onClick={() => loadMoreItems(images.length)} className={style.loadMoreBtn}><Translate>Load all</Translate></div>
                </div> : null
            */}
        </div>
    )
}

export default Design